import { render, staticRenderFns } from "./CartItemInfo.vue?vue&type=template&id=a9361b14"
import script from "./CartItemInfo.vue?vue&type=script&lang=js"
export * from "./CartItemInfo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {InventoryStatus: require('/codebuild/output/src410847008/src/client/components/productView/InventoryStatus.vue').default,CartItemsViewPartPrice: require('/codebuild/output/src410847008/src/client/components/CartItemsViewPartPrice.vue').default,NuxtImg: require('/codebuild/output/src410847008/src/client/components/NuxtImg.vue').default})
